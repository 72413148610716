var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-data-table',{attrs:{"headers":_vm.headers,"flat":"","items":_vm.users,"items-per-page":10},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('h1',{staticClass:"mb-0 text-xl-h6",on:{"click":function($event){return _vm.$router.push('users/'+item.id+'/profile')}}},[_vm._v(_vm._s(item.name)+" "+_vm._s(item.last_name))]),_c('p',{staticClass:"ma-0"},[_c('small',[_vm._v(_vm._s(item.email))])])]}},{key:"item.roles",fn:function(ref){
var item = ref.item;
return _vm._l((item.roles),function(role){return _c('v-chip',{staticClass:"mr-2",attrs:{"label":""}},[_vm._v(_vm._s(role.name))])})}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"my-6"},[_vm._v(_vm._s(_vm._f("moment")(item.created_at,"DD MMM YY")))])]}},{key:"item.controls",fn:function(ref){
var item = ref.item;
return [_c('users-actions-dropdown',{attrs:{"user":item}})]}}])}),_c('users-role-change-dialog',{on:{"user-update":function($event){return _vm.fetchUsers()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }