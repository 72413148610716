<template>
    <section id="users-list-page">
        <!-- dark -->
        <v-col cols="8">
            <v-card>
                <v-card-title>Users</v-card-title>
                <v-card-subtitle>
                    View, Manage <code>users</code>
                </v-card-subtitle>
                <v-card-text>
                    <v-sheet>
                        <users-table/>
                    </v-sheet>
                </v-card-text>
            </v-card>
        </v-col>
    </section>
</template>

<script>
    import UsersTable from "./components/UsersTable";

    export default {
        name: 'UsersList',
        components: {UsersTable}
    }
</script>
