<template>
    <v-menu bottom right>
        <!-- Activator -->
        <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
        </template>
        <!-- Dropdown Content -->
        <v-list>
            <v-list-item-group color="primary">
                <v-list-item>
                    <v-list-item-title disabled>Profile</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="can('users.role.edit')">
                    <v-list-item-title @click="onUserRoleEdit(user)">Role</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="can('users.delete')">
                    <v-list-item-title @click="onUserDeletion(user)">Delete</v-list-item-title>
                </v-list-item>
            </v-list-item-group>
        </v-list>
    </v-menu>
</template>
<script>

    import {EventBus} from "../../../../../bootstrap/EventBus";

    export default {
        props: ['user'],
        name: 'UsersActionsDropdown',
        data: () => ({}),
        methods: {
            onUserDeletion(user) {
                EventBus.$emit('user-deletion', user);
            },
            onUserRoleEdit(user) {
                EventBus.$emit('user-role-edit', user);
            }
        }
    }
</script>
