<template>
    <v-dialog v-model="showPopup" width="500">
        <v-sheet id="create-expense" class="pa-10" color="white">
            <h1 class="mb-5">Change Role</h1>
            <p>This action will perform role update for {{user.name}}</p>
            <v-select v-model="role" flat outlined
                      item-text="name"
                      item-value="id"
                      :items="roles">
            </v-select>
            <v-form class="mt-2">
                <v-btn class="mr-4" @click="showPopup = false">Cancel</v-btn>
                <v-btn color="primary" @click="updateRole">Update</v-btn>
            </v-form>
        </v-sheet>
    </v-dialog>
</template>

<script>
    import {mapActions} from "vuex";
    import {EventBus} from "../../../../../bootstrap/EventBus";
    import dictionaries from "../../../../../api/modules/dictionaries";
    import users from "../../../../../api/modules/users";

    export default {
        name: 'UsersRoleChangeDialog',
        data: function () {
            return {
                showPopup: false,
                roles: [],
                role: null,
                user: {},
            }
        },
        created: function () {
            this.registerEvents();
            this.fetchInitialData();
        },
        methods: {
            ...mapActions(['updateUser']),
            registerEvents: function () {
                EventBus.$on('user-role-edit', (user) => {
                    this.user = user;
                    this.showPopup = true;
                });
            },
            fetchInitialData: function () {
                dictionaries.fetchRolesDictionary().then(res => {
                    this.roles = res.data;
                })
            },
            updateRole: function () {
                users.updateUserRole(this.user, this.role).then(res => {
                    this.showPopup = false;

                    this.$emit('user-update');
                });
            },
        },
    }
</script>
