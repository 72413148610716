<template>
    <section>
        <v-data-table :headers="headers" flat :items="users" :items-per-page="10">
            <template v-slot:item.name="{item}">
                <h1 class="mb-0 text-xl-h6" @click="$router.push('users/'+item.id+'/profile')">{{item.name}} {{item.last_name}}</h1>
                <p class="ma-0"><small>{{item.email}}</small></p>
            </template>
            <template v-slot:item.roles="{item}">
                <v-chip label class="mr-2" v-for="role in item.roles">{{role.name}}</v-chip>
            </template>
            <template v-slot:item.created_at="{item}">
                <div class="my-6">{{item.created_at | moment("DD MMM YY") }}</div>
            </template>
            <template v-slot:item.controls="{item}">
                <users-actions-dropdown :user="item"/>
            </template>
        </v-data-table>
        <users-role-change-dialog v-on:user-update="fetchUsers()"/>
    </section>
</template>
<script>
    import users from "../../../../../api/modules/users";
    import UsersActionsDropdown from "./UsersActionsDropdown";
    import UsersRoleChangeDialog from "./UsersRoleEditDialog";

    export default {
        name: 'UsersTable',
        components: {UsersRoleChangeDialog, UsersActionsDropdown},
        data: () => ({
            headers: [
                {text: 'Name', value: 'name'},
                {text: 'Role', value: 'roles'},
                {text: 'Phone', value: 'phone'},
                {text: 'Registered', value: 'created_at'},
                {text: 'Controls', value: 'controls'},
            ],
            users: [],
        }),
        created() {
            this.fetchUsers();
        },
        methods: {
            fetchUsers: function () {
                users.fetchUsers().then(res => {
                    this.users = res.data;
                })
            },
        }
    }
</script>
